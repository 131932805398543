import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link } from 'gatsby';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperBasic from '../Swiper/SwiperBasic';

interface RoundProps {
  rounds: RoundData[];
}

export interface RoundData {
  id: string | number;
  categoryType: string | undefined;
  category: string | undefined;
  message: string;
  name: string | undefined;
  relUrl: string | undefined;
  src: ImgHTMLAttributes<any>['src'];
}
export const SwiperContainer = styled(Swiper)`
  .swiper-slide {
    width: 368px;

    & + .swiper-slide {
      margin-left: 40px;
    }

    ${breakpoint(`tablet`)} {
      padding: 0 8px;
    }
    :first-child {
      margin-left: calc((100% - 1120px) / 2);
    }
    :last-child {
      margin-right: calc((100% - 1120px) / 2);
    }
    ${breakpoint(`mobile`)} {
      width: 280px;
      & + .swiper-slide {
        margin-left: 16px;
      }
      :first-child {
        margin-left: 0;
      }
      :last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ListBanner = styled.div`
  width: 100%;
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.05);
  }
`;
export const BannerImage = styled.div`
  width: 100%;
  height: 368px;
  overflow: hidden;
  border-radius: 100%;
  position: relative;
  img {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  ${breakpoint(640)} {
    height: 280px;
  }
`;
export const ListBannerMessage = styled.div`
  box-sizing: border-box;
  width: 256px;
  border-radius: 0 0 40px 0;
  box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
  position: relative;
  padding: 28px 16px;
  margin-top: -88px;
  .ListBanner-round--cg {
    display: inline-block;
    background: #1cabe2;
    padding: 0 24px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    position: absolute;
    left: 0;
    top: -20px;
    ${breakpoint(640)} {
      font-size: 14px;
    }
  }
  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #2d2926;
    height: 110px;
    letter-spacing: -0.8px;
  }
  .ListBanner-round--name {
    display: inline-block;
    position: relative;
    font-size: 16px;
    padding: 16px 0 0 0;
    letter-spacing: -0.8px;
    &::before {
      content: 'ㅡ';
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin-right: 4px;
    }
  }
  ${breakpoint(`mobile`)} {
    width: 195px;
    padding: 28px 10px;
    .ListBanner-round--cg {
      padding: 0 20px;
    }
    p {
      font-size: 14px;
      line-height: 2;
      letter-spacing: -0.8px;
      height: 84px;
    }
    .ListBanner-round--name {
      font-size: 14px;
      padding: 12px 0 0 0;
    }
  }
`;

const RoundThumbnailCard: FC<RoundProps> = ({ rounds }) => (
  <SwiperBasic>
    <SwiperContainer
      slidesPerView="auto"
      navigation
      scrollbar={{ draggable: true }}
    >
      {rounds.map((round) => (
        <SwiperSlide key={round.id}>
          <ListBanner>
            <Link to={round.relUrl}>
              <BannerImage>
                <img src={round.src} alt={round.name} />
              </BannerImage>
              <ListBannerMessage>
                <span className="ListBanner-round--cg">
                  {round.categoryType}
                </span>
                <p>{round.message}</p>
                <span className="ListBanner-round--name">
                  {`${round.name} ${round.categoryType}`}
                </span>
              </ListBannerMessage>
            </Link>
          </ListBanner>
        </SwiperSlide>
      ))}
    </SwiperContainer>
  </SwiperBasic>
);
export default RoundThumbnailCard;
