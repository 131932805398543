import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import 'swiper/swiper.min.css';
import Button from './Button';
import Image from './Image';
import MainTabButton from './MainTabButton';
import { Tit } from './Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { SupportStep1Data } from '@/page-blocks/donate/DonateStep1';
import { navigate } from 'gatsby';
import React, { FC, ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { A11y, Navigation, Scrollbar } from 'swiper';
import {
  SUPPORT_CATEGORY_REGULAR_WORLD,
  SUPPORT_TERM_REGULAR,
} from '@/helpers/SupportHelper';
import { GtmButtonDonate } from './Button.gtm';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';

SwiperCore.use([Navigation, Scrollbar, A11y]);

const PannelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 48px;
  dt,
  dd {
    display: inline-block;
    vertical-align: middle;
  }
  dd {
    overflow-x: auto;
  }
  ${breakpoint(`mobile`)} {
    margin-bottom: 24px;

    dd {
      width: 100%;
    }
    dt,
    .col-left {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`;

const UiTabPanel = styled.ul`
  background: #fff;
  display: inline-flex;
  & > li {
    margin-right: 16px;
    white-space: nowrap;
  }
`;
const TextTabButton = styled(MainTabButton)`
  line-height: 1.1;
  height: auto;
  background: none;
  padding: 0;
  display: inline-block;
  margin-right: 16px;
  &:disabled {
    background: transparent;
    border-radius: 0;
  }
  ${breakpoint(800)} {
    display: block;
  }
`;
export const CardLayout = styled.div`
  width: 100%;
`;

export const CardImage = styled.div`
  width: 100%;
  overflow: hidden;
  border: solid 1px #e5e6e8;
  .img-box {
    padding-top: 100%;
  }
`;

export const CardSource = styled(Image)`
  max-width: 100%;
`;
export const CardTextArea = styled.div`
  padding: 24px 24px 0 0;

  h4 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: -1.2px;
    color: #2d2926;
  }

  h5 {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    letter-spacing: -0.8px;
    color: #2d2926;
  }

  p {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: -1.2px;
    color: #2d2926;
  }

  ${breakpoint(`mobile`)} {
    padding: 16px 16px 0 0;

    h4 {
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: -0.8px;
    }

    h5 {
      margin-bottom: 16px;
      font-size: 14px;
    }

    p {
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: -0.8px;
    }
  }
`;

const CategoryButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 94px;
  img {
    width: 56px;
    display: block;
    margin: 0 auto 8px;
  }
  ${breakpoint(1024)} {
    white-space: nowrap;
    overflow-x: scroll;
    display: block;
    button {
      white-space: normal;
      font-size: 16px;
      & + button {
        margin-left: 8px;
      }
    }
    img {
      width: 48px;
    }
  }
  ${breakpoint(`mobile`)} {
    img {
      margin-bottom: 0;
    }
    button {
      width: 96px;
      height: 96px;
      font-size: 14px;
      text-align: center;
      padding: 0;
      line-height: 1.4;
    }
  }
`;
const CategoryButton = styled(Button)<{ active?: boolean }>`
  width: 136px;
  height: 136px;
  border: 1px solid #1cabe2;
  text-align: center;
  line-height: normal;
  background: ${(props) => (props.active === true ? `#1cabe2` : `#fff`)};
  color: ${(props) => (props.active === true ? `#fff` : `#1cabe2`)};
`;
const ProductList = styled.li``;
const ContentsContainer = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 1424px;
    background: #e2f0f6;
    position: absolute;
    left: 96px;
    top: 80px;
    bottom: 0;
    z-index: -1;
  }
  ${breakpoint(1160)} {
    &::before {
      top: 6.9vw;
    }
  }
  ${breakpoint(`mobile`)} {
    &::before {
      top: 65px;
      width: auto;
      left: -20px;
      right: -20px;
    }
  }
`;
const InterviewContainer = styled.div``;
const InterviewWrap = styled.div`
  padding-left: 40%;
  padding-top: 80px;
  position: relative;
  z-index: 1;

  ${breakpoint(1160)} {
    padding-top: 6.9vw;
  }

  ${breakpoint(`mobile`)} {
    padding: 0;
  }
`;
const InterviewImage = styled.div`
  width: 40%;
  position: absolute;
  left: 0;
  top: 0;

  ${breakpoint(`mobile`)} {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }
`;
const InterviewText = styled.p`
  width: 100%;
  padding-top: 80px;
  padding-left: 128px;
  padding-bottom: 76px;
  z-index: 1;

  ${Tit} {
    line-height: 1.56;
    letter-spacing: -0.8px;
  }

  ${breakpoint(1160)} {
    padding-top: 6.9vw;
    padding-left: 11.03vw;
  }
  ${breakpoint(`mobile`)} {
    padding: 40px 0;
    ${Tit} {
      font-size: 18px;
    }
  }
`;
const Interviewer = styled.span`
  display: block;
  margin-top: 8px;
  font-weight: normal;
  line-height: 2;
  letter-spacing: -0.8px;
  ${breakpoint(`mobile`)} {
    margin-top: 16px;
  }
`;

const GiftContainer = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 40%;
  position: relative;

  &::before {
    content: '';
    display: block;
    background: #1cabe2;
    position: absolute;
    left: 96px;
    right: -20px;
    top: 0;
    bottom: 0;
  }

  ${breakpoint(`mobile`)} {
    padding-left: 0;
    &::before {
      left: -20px;
    }
  }
`;
const GiftList = styled.ul`
  padding: 76px 0 85px;
  padding-left: 128px;

  ${breakpoint(1160)} {
    padding: 6.55vw 0 7.33vw;
    padding-left: 11.03vw;
  }
  ${breakpoint(`mobile`)} {
    padding: 34px 0;
  }
`;
const GiftItem = styled.li`
  position: relative;
  padding-left: 16px;
  &::before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background-color: #fff;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 12px;
  }
  & + li {
    margin-top: 16px;
  }
`;
interface ProductListProps {
  prices?: PriceData[];
  categories?: CategoryData[];
  gifts?: GiftData[];
  contents?: ContentsData[];
  ExtraButton?: ReactElement;
}

export interface PriceData {
  id: number | string;
  priceCode?: number | string;
  priceCodeName?: string;
  contentKey?: any;
}
export interface CategoryData {
  id: number | string;
  categoryName: string;
  categoryIcon?: string;
  categoryIconActive?: any;
}
export interface GiftData {
  id: string | number;
  name: string;
  status: string;
  giftText1?: string;
  giftText2?: string;
}
export interface ContentsData {
  id: string | number;
  title: string;
  name: string;
  imgPath?: string;
}

const getSupportPageUrl = (
  supportAmount: SupportStep1Data['supportAmount'],
) => {
  return `/donate/?supportTerm=${SUPPORT_TERM_REGULAR}&supportCategory=${SUPPORT_CATEGORY_REGULAR_WORLD}&supportAmount=${supportAmount}`;
};

export const PledgeTabPanel: FC<ProductListProps> = ({
  prices,
  categories,
  contents,
  gifts,
}) => {
  const [contentIndex, setContentIndex] = useState(0);
  const [categoryIndex, setCategoryIndex] = useState(0);

  const onButtonClick = useCallback((index: number) => {
    setContentIndex(index);
  }, []);
  const onCategoryClick = useCallback((index: number) => {
    setCategoryIndex(index);
  }, []);

  const [categoryName, setCategoryName] = useState(`교육`);
  const [status, setStatus] = useState(`30,000원`);
  const [price, setPrice] = useState(`30000`);

  return (
    <>
      <PannelContainer>
        <dl className="col-left">
          <dt>
            <TextTabButton className="text-tab" disabled>
              매월
            </TextTabButton>
          </dt>
          <dd>
            <UiTabPanel className="ui-tabpanel-list">
              {prices.map((price, index) => (
                <ProductList key={price.id}>
                  <MainTabButton
                    active={contentIndex === index}
                    type="button"
                    key={price.id}
                    onClick={() => {
                      onButtonClick(index);
                      setContentIndex(index);
                      setStatus(price.priceCode);
                      setPrice(price.priceValue);
                    }}
                  >
                    {price.priceCode}
                  </MainTabButton>
                </ProductList>
              ))}
            </UiTabPanel>
          </dd>
        </dl>
        <GtmButtonDonate
          type="button"
          text="정기후원하기"
          color="yellow"
          goto={getSupportPageUrl(price)}
          gtmInfo={{
            ...SelectItemDefaultData,
            itemId: SUPPORT_CATEGORY_REGULAR_WORLD,
            itemName: '세계 어린이 돕기',
            itemCategory: SUPPORT_TERM_REGULAR,
          }}
          onClickPreEvent={() => {
            // TODO onClick before call gtm
            // alert('click');
          }}
        />
        {/* <TextTabButton className="text-tab" disabled>
          정기후원 시
        </TextTabButton> */}
      </PannelContainer>
      <CategoryButtonContainer>
        {categories.map((category, index) => (
          <CategoryButton
            active={categoryIndex === index}
            onClick={() => {
              onCategoryClick(index);
              setCategoryIndex(index);
              setCategoryName(category.categoryName);
            }}
            key={category.id}
          >
            {categoryIndex === index ? (
              <img src={category.categoryIconActive} alt="" />
            ) : (
              <img src={category.categoryIcon} alt="" />
            )}
            {category.categoryName}
          </CategoryButton>
        ))}
      </CategoryButtonContainer>

      <ContentsContainer>
        {contents.map((content, index) => (
          <InterviewContainer key={content.id}>
            {categoryIndex === index ? (
              <InterviewWrap>
                <InterviewImage>
                  <img src={content.imgPath} alt="" />
                </InterviewImage>
                <InterviewText>
                  <Tit size="s3-1">{content.title}</Tit>
                  <Interviewer>{content.name}</Interviewer>
                </InterviewText>
              </InterviewWrap>
            ) : null}
          </InterviewContainer>
        ))}
        {gifts
          ?.filter((g) => status === g.status && categoryName === g.name)
          .map((gift) => (
            <GiftContainer key={gift.id}>
              <GiftList>
                <GiftItem>
                  <Tit size="s4" color="white">
                    {gift.giftText1}
                  </Tit>
                </GiftItem>
                {gift.giftText2 && (
                  <GiftItem>
                    <Tit size="s4" color="white">
                      {gift.giftText2}
                    </Tit>
                  </GiftItem>
                )}
              </GiftList>
            </GiftContainer>
          ))}
      </ContentsContainer>
    </>
  );
};

export default PledgeTabPanel;
